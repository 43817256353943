import { useContext } from 'react';
import { ProfileContext } from '../contexts/ProfileContext';
export function useProfile() {
  var context = useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfile must be used within an ProfileProvider');
  }

  return context;
}