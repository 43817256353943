var menuItems = [{
  url: '/about',
  label: 'About'
}, {
  url: '/insights',
  label: 'Insights'
}, {
  url: '/resources',
  label: 'Resources',
  exact: false
}, {
  url: '/contact',
  label: 'Contact'
}];
export default menuItems;